import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useSocket } from "../../context/SocketContext.js"; // Adjust the import path accordingly
import "./call.css";
import useCallHook from "../../hooks/useCallHook.js";
import classnames from "classnames";
import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "../newCallPage/ActionButton.js";

const Call = forwardRef(
  (
    {
      receiverIDRef,
      peerConnectionRef,
      isRemoteStreamVisible,
      setIsRemoteStreamVisible,
      resetPeerConnection,
      incomingCall,
      setIncomingCall,
      setIsCallStarted,
      setShowVideoPopup,
      showVideoPopup,
    },
    ref
  ) => {
    const socket = useSocket();
    const localStreamRef = useRef(null);
    const remoteStreamRef = useRef(null);

    const iceCandidatesQueue = useRef([]);

    const [isCalling, setIsCalling] = useState(false);
    const [isRinging, setIsRinging] = useState(false);

    const [isVideoOn, setIsVideoOn] = useState(true); // Track video status
    const [isAudioOn, setIsAudioOn] = useState(true); // Track audio status
    const [isSpeakerOn, setIsSpeakerOn] = useState(false);
    const [isMuted, setIsMuted] = useState(false);

    const isWaitingForReceiverRef = useRef(false);

    const { mediaDeviceAccess, handleError } = useCallHook();

    const stopStream = () => {
      const localStream = localStreamRef.current?.srcObject;
      if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
        localStreamRef.current.srcObject = null;
      }

      const remoteStream = remoteStreamRef.current?.srcObject;
      if (remoteStream) {
        remoteStream.getTracks().forEach((track) => track.stop());
        remoteStreamRef.current.srcObject = null;
      }
    };

    const resetStatus = () => {
      setIsCalling(false);
      setIsRinging(false);
      setIsRemoteStreamVisible(false);
      setIsCallStarted(false);
      setIncomingCall(null);
      setShowVideoPopup(false);
      isWaitingForReceiverRef.current = false;
      stopStream();
      resetPeerConnection();
    };

    const cleanupOnUnload = () => {
      socket.emit("end-call", { to: receiverIDRef.current });
      resetStatus();
      // if (socket) {
      //   socket.disconnect();
      // }
    };

    useEffect(() => {
      if (socket) {
        window.addEventListener("beforeunload", cleanupOnUnload);
        window.addEventListener("unload", cleanupOnUnload); // Handle navigation away

        socket.on("receiving-ice-candidate", async (data) => {
          const decodedCandidate = JSON.parse(
            decodeURIComponent(data.candidate)
          );
          if (peerConnectionRef.current.remoteDescription) {
            await peerConnectionRef.current.addIceCandidate(
              new RTCIceCandidate(decodedCandidate)
            );
          } else {
            iceCandidatesQueue.current.push(decodedCandidate);
          }
        });

        socket.on("receiving-call", (data) => {
          console.log("Call offer received from:", data.from);
          if (isWaitingForReceiverRef.current) {
            handleAnswerCall(data);
          } else {
            setIncomingCall(data);
          }
        });

        socket.on("receiving-call-answer", async (data) => {
          try {
            const signalingState = peerConnectionRef.current.signalingState;
            if (
              signalingState === "have-local-offer" ||
              signalingState === "stable"
            ) {
              console.log("Received answer");
              await peerConnectionRef.current.setRemoteDescription(
                new RTCSessionDescription(data.answer)
              );
              setIsCalling(false);
              setIsRinging(false);
              setIsCallStarted(true);
              iceCandidatesQueue.current.forEach(async (candidate) => {
                await peerConnectionRef.current.addIceCandidate(
                  new RTCIceCandidate(candidate)
                );
              });
              iceCandidatesQueue.current = [];
            } else {
              console.warn(
                "Cannot set remote answer. Current signaling state:",
                signalingState
              );
            }
          } catch (error) {
            console.error("Error handling answer:", error);
          }
        });

        socket.on("call-rejected", (data) => {
          console.log("Call rejected by:", data.from);
          handleEndCall({
            isRejectingCall: true,
          });
          // handleRejectCall(true);
        });

        socket.on("end-call", () => {
          console.log("Call ended by the other party");
          // handleEndCall(true); // Pass true to indicate it was ended by the other party
          handleEndCall({
            callEndByOtherParty: true,
          });
        });

        return () => {
          window.removeEventListener("beforeunload", cleanupOnUnload);
          window.removeEventListener("unload", cleanupOnUnload);

          socket.off("receiving-ice-candidate");
          socket.off("receiving-call");
          socket.off("receiving-call-answer");
          socket.off("call-rejected");
          socket.off("end-call");
        };
      }
    }, [socket]);

    const handleStartCall = () =>
      handleError(async () => {
        setShowVideoPopup(true);
        setIsCallStarted(true);

        isWaitingForReceiverRef.current = true;

        // Get media stream
        const stream = await mediaDeviceAccess();
        localStreamRef.current.srcObject = stream;

        peerConnectionRef.current.onicecandidate = ({ candidate }) => {
          if (candidate) {
            const encodedCandidate = encodeURIComponent(
              JSON.stringify(candidate)
            );

            socket.emit("sending-ice-candidate", {
              candidate: encodedCandidate,
              to: receiverIDRef.current,
            });
          }
        };

        peerConnectionRef.current.ontrack = (event) => {
          remoteStreamRef.current.srcObject = event.streams[0];
        };

        stream.getTracks().forEach((track) => {
          peerConnectionRef.current.addTrack(track, stream);
        });

        // Create and send offer
        const offer = await peerConnectionRef.current.createOffer({
          iceRestart: true,
        });
        await peerConnectionRef.current.setLocalDescription(offer);

        console.log("Sending call offer");
        socket.emit("calling-user", {
          offer,
          to: receiverIDRef.current,
        });

        socket.emit(
          "check-user-online",
          { to: receiverIDRef.current },
          (isOnline) => {
            if (isOnline) {
              setIsRinging(true);
            } else {
              setIsCalling(true);
            }
          }
        );
      }, "Start call");

    const handleAnswerCall = () =>
      handleError(async (paramIncomingCall) => {
        const { from, offer } = incomingCall || paramIncomingCall;
        receiverIDRef.current = from;

        const signalingState = peerConnectionRef.current.signalingState;
        if (
          signalingState === "stable" ||
          signalingState === "have-local-offer"
        ) {
          // Set ICE candidate listener
          peerConnectionRef.current.onicecandidate = ({ candidate }) => {
            if (candidate) {
              const encodedCandidate = encodeURIComponent(
                JSON.stringify(candidate)
              );
              socket.emit("sending-ice-candidate", {
                to: from,
                candidate: encodedCandidate,
              });
            }
          };

          // Track remote stream
          peerConnectionRef.current.ontrack = (event) => {
            remoteStreamRef.current.srcObject = event.streams[0];
          };

          await peerConnectionRef.current.setRemoteDescription(
            new RTCSessionDescription(offer)
          );

          // Get user media
          const stream = await mediaDeviceAccess();

          // Add local stream
          localStreamRef.current.srcObject = stream;
          stream.getTracks().forEach((track) => {
            peerConnectionRef.current.addTrack(track, stream);
          });

          // Create and send answer
          const answer = await peerConnectionRef.current.createAnswer();
          await peerConnectionRef.current.setLocalDescription(answer);

          socket.emit("sending-call-answer", {
            answer,
            to: from,
          });

          // Process any queued ICE candidates after setting remote description
          iceCandidatesQueue.current.forEach(async (candidate) => {
            await peerConnectionRef.current.addIceCandidate(
              new RTCIceCandidate(candidate)
            );
          });
          iceCandidatesQueue.current = [];

          setIsCallStarted(true);
          setShowVideoPopup(true);
          setIncomingCall(null);
          setIsCalling(false);
          setIsRinging(false);
          isWaitingForReceiverRef.current = false;
        } else {
          console.warn(
            "Cannot handle incoming call. Current signaling state:",
            signalingState
          );
        }
      }, "Answer call");

    const handleEndCall = ({
      isRejectingCall = false,
      callEndByOtherParty = false,
    } = {}) => {
      if (!callEndByOtherParty) {
        const eventType = isRejectingCall ? "call-rejected" : "end-call";
        const targetID = isRejectingCall
          ? incomingCall?.from
          : receiverIDRef.current;
        socket.emit(eventType, { to: targetID });
      }

      if (peerConnectionRef.current) {
        peerConnectionRef.current.close();
        peerConnectionRef.current = null;
      }

      resetStatus();
    };

    const toggleMediaTrack = (trackType, setTrackState) => {
      const localStream = localStreamRef.current?.srcObject;
      if (localStream) {
        const tracks =
          trackType === "video"
            ? localStream.getVideoTracks()
            : localStream.getAudioTracks();
        tracks.forEach((track) => (track.enabled = !track.enabled));
        setTrackState((prev) => !prev);
      }
    };

    useImperativeHandle(ref, () => ({
      handleAnswerCall,
      handleStartCall,
      handleEndCall,
    }));

    return (
      <>
        <div className={classnames("call-window", "active")}>
          <video id="peerVideo" ref={remoteStreamRef} autoPlay />
          <video id="localVideo" ref={localStreamRef} autoPlay muted />
          <div className="video-control">
            <ActionButton
              key="btnVideo"
              icon={isVideoOn ? faVideo : faVideoSlash}
              // disabled={!isVideoOn}
              onClick={() => toggleMediaTrack("video", setIsVideoOn)}
            />
            <ActionButton
              key="btnAudio"
              icon={isAudioOn ? faMicrophone : faMicrophoneSlash}
              // disabled={!isAudioOn}
              onClick={() => toggleMediaTrack("audio", setIsAudioOn)}
            />
            <ActionButton
              className="hangup"
              icon={faPhone}
              onClick={handleEndCall}
            />
          </div>
        </div>

        {isCalling && (
          <div className="overlay bluring">
            <div className="status-message animation">Calling...</div>
          </div>
        )}

        {isRinging && (
          <div className="overlay bluring">
            <div className="status-message animation">Ringing...</div>
          </div>
        )}
      </>
    );
  }
);

export default Call;
