import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "./chatPage/ChatHeader.js";
import ChatContainer from "./chatPage/ChatContainer.js";
import UploadPreview from "./chatPage/UploadPreview.js";
import SendMessageForm from "./chatPage/SendMessageForm.js";
import backgroundImage from "../assets/wallapaper.jpeg";
import { useCallContext } from "../context/CallContext.js";
import { useSocket } from "../context/SocketContext.js"; // Adjust the import path accordingly

const MessagePage = () => {
  const params = useParams();
  const socket = useSocket();

  const user = useSelector((state) => state?.user);
  const [dataUser, setDataUser] = useState({
    name: "",
    email: "",
    profile_pic: "",
    online: false,
    _id: "",
  });

  const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false);
  const [message, setMessage] = useState({
    text: "",
    imageUrl: "",
    videoUrl: "",
  });

  const [loading, setLoading] = useState(false);
  const [allMessage, setAllMessage] = useState([]);
  // const currentMessage = useRef(null);

  const chatContainerRef = useRef(null);
  const [isChatInFocus, setIsChatInFocus] = useState(true); // Track if chat is in focus

  const { receiverIDRef, handleStartCall, isCallStarted } = useCallContext();

  const handleUploadImageVideoOpen = () =>
    setOpenImageVideoUpload(!openImageVideoUpload);
  const handleClearUploadImage = () => setMessage({ ...message, imageUrl: "" });
  const handleClearUploadVideo = () => setMessage({ ...message, videoUrl: "" });

  useEffect(() => {
    // Handle visibility state of the document (whether chat is focused)
    const handleVisibilityChange = () => {
      setIsChatInFocus(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      if (receiverIDRef) receiverIDRef.current = params.userId;

      socket.emit("message-page", params.userId);

      socket.on("message-user", (data) => {
        setDataUser(data);
      });

      socket.on("message", (data) => {
        setAllMessage(data);
      });

      socket.on("message-seen", ({ messageIds }) => {
        setAllMessage((prevMessages) =>
          prevMessages.map((msg) =>
            messageIds.includes(msg._id) ? { ...msg, seen: true } : msg
          )
        );
      });
    }
  }, [socket, params?.userId, user]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setMessage((preve) => {
      return {
        ...preve,
        text: value,
      };
    });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();

    if (message.text || message.imageUrl || message.videoUrl) {
      if (socket) {
        socket.emit("new-message", {
          sender: user?._id,
          receiver: params.userId,
          text: message.text,
          imageUrl: message.imageUrl,
          videoUrl: message.videoUrl,
          msgByUserId: user?._id,
        });
        setMessage({
          text: "",
          imageUrl: "",
          videoUrl: "",
        });
      }
    }
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];

    setLoading(true);
    const localUrl = URL.createObjectURL(file);
    setLoading(false);
    setOpenImageVideoUpload(false);

    setMessage((preve) => {
      return {
        ...preve,
        imageUrl: localUrl,
      };
    });
  };

  const handleUploadVideo = async (e) => {
    const file = e.target.files[0];

    setLoading(true);
    const localUrl = URL.createObjectURL(file);
    setLoading(false);
    setOpenImageVideoUpload(false);

    setMessage((preve) => {
      return {
        ...preve,
        videoUrl: localUrl,
      };
    });
  };

  return (
    <div
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className="bg-no-repeat bg-cover"
    >
      <Header
        receiverUser={dataUser}
        currentUser={user}
        onStartCall={handleStartCall}
        callBtnVisible={!isCallStarted}
      />
      <ChatContainer
        allMessage={allMessage}
        chatContainerRef={chatContainerRef}
        user={user}
        isChatInFocus={isChatInFocus}
        socket={socket}
        params={params}
      />
      <UploadPreview
        message={message}
        handleClearUploadImage={handleClearUploadImage}
        handleClearUploadVideo={handleClearUploadVideo}
      />
      <SendMessageForm
        handleUploadImageVideoOpen={handleUploadImageVideoOpen}
        openImageVideoUpload={openImageVideoUpload}
        handleUploadImage={handleUploadImage}
        handleUploadVideo={handleUploadVideo}
        handleSendMessage={handleSendMessage}
        handleOnChange={handleOnChange}
        message={message}
      />
    </div>
  );
};

export default MessagePage;
